/* Reset default margin and padding for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Add some styling to the header */
.App-header {
  padding: 0 10%;
  background-color: #f0f0f0;
}

/* Style the main container */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Style the form */
form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  width: 100%;
}

input[type="text"] {
  flex: 1 1 auto;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

button[type="submit"] {
  flex: 0 0 auto;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 10px;
  margin-bottom: 10px;
}

/* Style the table */
table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Change to auto for better responsiveness */
}
table th,
table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.PostName {
  width: 50%;
}

/* Style the selected items list */
#Selected ul {
  padding-left: 0;
}

#Selected li {
  list-style-type: none;
  margin-bottom: 5px;
}

/* Responsive layout for smaller screens */
@media (max-width: 768px) {
  .App {
    align-items: flex-start;
    padding: 10px;
  }

  form {
    flex-direction: column;
  }

  button[type="submit"] {
    margin-left: 0;
  }

  /* Make the table take full width on smaller screens */
  #Result {
    width: 100%;
    overflow-x: auto;
  }

  #Selected {
    margin-top: 20px;
    width: 100%;
  }
}

.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Add some margin to separate from the table */
}
